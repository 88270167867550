<template>
  <div>
    <div class="section bg-gray-4">
      <div class="container">
        <div class="w-layout-grid grid-quarters cards-grid">
          <Post
            v-for="post in posts"
            :key="post.post_title"
            :post="post"
            :showTitle="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentService from "@/services/contentService.js";
import Post from "@/components/EditableTemplatePost.vue";
export default {
  data() {
    return {
      posts: []
    };
  },
  props: {
    Category: String
  },
  components: {
    Post
  },
  created() {
    ContentService.getContentFilter("printables", this.Category)
      .then(response => {
        console.log(response.data.data);
        this.posts = response.data.data.printables;
      })
      .catch(error => {
        console.log("There was an error getting the templates", error);
      });
  }
};
</script>

<style scoped>
</style>